// Libs.
import React from 'react';
// Assets.
import styles from './featured-heading.module.scss';
import {Link} from 'gatsby';
import {useImageStaticQuery} from '../../../hooks/useImagesStaticQuery';
import Img from 'gatsby-image';
import * as PropTypes from 'prop-types';
import { get } from 'lodash';

const FeaturedHeading = ({ featured }) => {
  const imageData = useImageStaticQuery(featured.image);
  const image = get(featured,'image.fluid', get(imageData,'childImageSharp.fluid'));

  return (
    <div className={styles.featuredHeading}>
      <div className={styles.featuredHeadingContent}>
        {featured.heading &&
          <h1 className={styles.featuredHeadingHeader}>
            {featured.heading}
          </h1>
        }
        {featured.tag &&
          <p className={styles.featuredHeadingTag}>
            {featured.tag}
          </p>
        }
        {featured.title &&
          <p className={styles.featuredHeadingTitle}>
            {featured.title}
          </p>
        }
        {featured.link &&
          <Link
            className={styles.featuredHeadingLink}
            to={featured.link.uri.replace('internal:/', '')}
          >
            {featured.link.title}
          </Link>
        }
      </div>
      <div className={styles.featuredHeadingImage}>
        {image != null && <Img fluid={image} />}
      </div>
    </div>
  );
};

FeaturedHeading.propTypes = {
  featured: PropTypes.shape({
    heading: PropTypes.string,
    tag: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string,
    })
  })
};

export default FeaturedHeading;
