// Libs.
import React from 'react';
// Components.
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import RecipesListing from '../components/recipes/recipes-listing';

const RecipesPage = () => {
  return (
    <Layout>
      <SEO title="Recipes" />
      <RecipesListing />
    </Layout>
  );
};

export default RecipesPage;
