import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { has } from 'lodash';
import { formatRecipeFromNode } from '../libs/formatFromGraph';

export const useRecipesStaticQuery = (id = 0, idKey = 'nid') => {
  let Recipes = useLoadRecipeNodes();

  // Filter by ID.
  if (id) {
    // console.log('prefilter Recipes', id, results);
    Recipes = Recipes.find((node) => node[idKey] === parseInt(id));
    // console.log('filtered Recipes', id, results);
    Recipes = Recipes || {};
  }

  return Recipes;
};

/**
 * Load the Recipes from graphql for site usage.
 *
 * @returns {Array}
 */
const useLoadRecipeNodes = () => {
  const { allNodeRecipe } = useStaticQuery(graphql`
    query loadStaticAllRecipeList {
      allNodeRecipe {
        edges {
          node {
            id
            nid: drupal_internal__nid
            advice:field_advice
            calories:field_calories_per_serving
            title
            cook: field_cook
            description: field_description
            fat: field_fat
            carbs:field_carbs
            fibre: field_fibre
            directions: field_instructions
            ingredients: field_ingredients
            nutritional: field_nutritional_value
            prep: field_prep
            total: field_preparation_time_total_
            protein: field_protein
            serves: field_serves
            sodium: field_sodium
            sugar: field_sugar
            relationships {
              image: field_file_image {
                uri {
                  url
                }
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 945) {
                      ...GatsbyImageSharpFluid
                    }
                    teaser: fluid(maxHeight: 203) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              season: field_season {
                id
                name
                drupal_id
              }
              filter: field_filter {
                id
                name
                tid: drupal_internal__tid
              }
            }
          }
        }
      }
    }
  `);
  // const allNodeRecipe = {};
  return useMemo(() => digestRecipeNodes(allNodeRecipe), allNodeRecipe);
};

const digestRecipeNodes = (allNodeRecipe) => {
  if (!has(allNodeRecipe, 'edges')) {
    return [];
  }
  return allNodeRecipe.edges.map((edge) => formatRecipeFromNode(edge.node));
};
