// Libs.
import React from 'react';
// Assets.
import styles from './featured-recipes.module.scss';
//Components.
import FeaturedHeading from '../common/heading-featured/featured-heading';

const FeaturedRecipes = ({ featured }) => {
  return (
    <div className={styles.featuredRecipes}>
      <FeaturedHeading featured={featured} />
    </div>
  );
};

export default FeaturedRecipes;
