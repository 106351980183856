import { useStaticQuery, graphql } from 'gatsby';
import {drupalTransformer} from '../libs/transform/index'

export const useRecipesPageQuery = () => {
  return drupalTransformer.transformNode(useRecipesPage());
};

const useRecipesPage = () => {
  return useStaticQuery(graphql`
    query useRecipesPageQuery {
      featured: configPagesRecipesPage {
        title: field_featured_recipe_title
        heading: field_recipe_heading
        tag: field_featured_recipe_tag
        link: field_featured_recipe_link {
          title
          uri
        }
        relationships {
          image: field_featured_recipe_image {
            localFile {
              childImageSharp {
                fluid {
                  srcSet
                  src
                  base64
                  aspectRatio
                  sizes
                }
              }
            }
          }
        }
      }
    }
  `);
};
