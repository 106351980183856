// Libs.
import React from 'react';
// Assets.
import styles from './recipes-listing.module.scss';
// Components.
import FeaturedRecipes from './featured-recipes';
import RecipesFilterGrid from './recipes-filter-grid';
import { useRecipesPageQuery } from '../../hooks/useRecipesPageQuery';

const RecipesListing = () => {
  const data = useRecipesPageQuery();
  return (
    <div className={styles.recipesLanding}>
      <FeaturedRecipes featured={data.featured} />
      <RecipesFilterGrid />
    </div>
  );
};

export default RecipesListing;
