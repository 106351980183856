// Libs
import React, { useState, useEffect, useLayoutEffect } from 'react';
import styles from './recipes-filter-grid.module.scss';
import { filter, get, intersection, isArray, remove, take, uniqBy,uniqueId } from 'lodash';
import Card from '../common/cards/card';
import { redirectKeyPressHandler } from '../../libs/accessibility';
import locale from '../../locale/locale';
import { useRecipesStaticQuery } from '../../hooks/useRecipesStaticQuery';
import Memoized from '../common/Memoized';
import { useStateValue } from '../../store/state';
import {
  AddRecipeFilter,
  RemoveRecipeFilter,
  ClearAllRecipeFilters,
  SetRecipePage,
} from '../../store/recipeFilters/actions';
import { pushEvent } from '../../libs/dataLayer';

const RecipesFilterGrid = () => {
  const seasons = ['winter', 'spring', 'summer', 'fall', 'holiday', 'holidays'];
  const [state, dispatch] = useStateValue();
  const [page, loadPage] = useState(state.recipeFilters.page || 1);
  const recipes = useRecipesStaticQuery();

  const itemsCount = () => {
    const width = typeof window !== 'undefined' ? window.innerWidth : 1170;
    let count = 9;
    if (width <= 990) {
      count = 8;
      if (width < 575) {
        count = 5;
      }
    }
    return count;
  };


  const sortRecipes = (recipeList) => {
    if (isArray(recipeList)) {
      recipeList.sort((a, b) => {
        const [season_a, year_a] = a.season.name.split(' ');
        const [season_b, year_b] = b.season.name.split(' ');

        if (parseInt(year_a) > parseInt(year_b)) {
          return -1;
        } else if (parseInt(year_a) < parseInt(year_b)) {
          return 1;
        } else {
          if (seasons.indexOf(season_a.toLowerCase()) > seasons.indexOf(season_b.toLowerCase())) {
            return -1;
          } else if (
            seasons.indexOf(season_a.toLowerCase()) < seasons.indexOf(season_b.toLowerCase())
          ) {
            return 1;
          } else {
            return 0;
          }
        }
      });
    }
    return recipeList || [];
  };

  const [filters, setFilters] = useState([]);
  const [activeFilters, setActiveFilters] = useState(state.recipeFilters.filters || []);
  const [visibleRecipes, setVisibleRecipes] = useState(sortRecipes(recipes));
  const [filteredRecipes, setFilteredRecipes] = useState(visibleRecipes);

  useEffect(() => {
    // Build the filters once on load.
    let filterList = [];
    for (const recipe of recipes) {
      const localFilters = get(recipe, 'filter', null);
      if (isArray(localFilters)) {
        for (const fil of localFilters) {
          fil.selected = false;
          fil.name = fil.name.trim();
          filterList.push(fil);
        }
      }
    }
    filterList = uniqBy(filterList, 'tid');
    setFilters(filterList);

    // console.log('onLoad', {
    //   filterList,
    //   visibleRecipes,
    // });
  }, []);

  // When filters change, update recipes.
  useLayoutEffect(() => {
    const recipeList = filter(recipes, (recipe) => {
      const recipeFilters = isArray(recipe.filter) ? recipe.filter : [];
      const recipeFilterIds = recipeFilters.map((filter) => filter.tid);
      const filterIntersection = intersection(activeFilters, recipeFilterIds);
      // All checked filters should intersect with recipe filters (tags) so
      // filter checked options behaves as AND results inclusion.
      return filterIntersection.length === activeFilters.length;
    });
    setFilteredRecipes(sortRecipes(recipeList));
    setVisibleRecipes(take(recipeList, itemsCount() * page));
  }, [activeFilters]);

  // When the pagination changes, update visible list of recipes.
  useLayoutEffect(() => {
    const recipeList = take(filteredRecipes, itemsCount() * page);
    setVisibleRecipes(sortRecipes(recipeList));
    dispatch(SetRecipePage(page));
  }, [page]);

  // Handle a filter being toggled.
  const toggleFilter = (tid) => {
    let newActiveFilters = [...activeFilters];
    if (newActiveFilters.indexOf(tid) === -1) {
      newActiveFilters.push(tid);
      dispatch(AddRecipeFilter(tid));
    } else {
      remove(newActiveFilters, (n) => n === tid);
      dispatch(RemoveRecipeFilter(tid));
    }

    pushEvent('interaction', 'RecipeFilters', newActiveFilters);
    // After the filters are changed, ensure to reset the pagination to the first page.
    setActiveFilters(newActiveFilters);
    loadPage(1);
    // console.log('update filters', tid, newActiveFilters);
  };

  // Reset active filters.
  const resetFilters = () => {
    pushEvent('interaction', 'RecipeFilters', newActiveFilters);
    setActiveFilters([]);
    dispatch(ClearAllRecipeFilters());
  };

  // Load the next page of results
  const loadNextPage = () => {
    loadPage(page + 1);
  };

  return (
    <Memoized deps={[visibleRecipes, activeFilters, page]}>
      <div className={styles.cardFilterList}>
        <h2 className={styles.cardsListItemsHead}>{locale.recipeLandingPage.filterTitle}</h2>
        <div className={styles.cardsFilter}>
          <h3>{locale.recipeLandingPage.filterLabel}</h3>
          <button
            className={styles.cardsFilterClear}
            onClick={resetFilters}
            onKeyPress={redirectKeyPressHandler(resetFilters)}
          >
            {locale.recipeLandingPage.filterClear}
          </button>
          <div className={styles.cardsFilterButtons}>
            {filters.map((currentFilter, index) => {
              return (
                <button
                  key={uniqueId(`recipe_filter_${index}_`)}
                  className={styles.cardsFilterButton}
                  data-active={activeFilters.indexOf(currentFilter.tid) !== -1}
                  onClick={() => toggleFilter(currentFilter.tid)}
                  onKeyPress={redirectKeyPressHandler(() => toggleFilter(currentFilter.tid))}
                >
                  {currentFilter.name}
                </button>
              );
            })}
          </div>
        </div>
        <div className={styles.cardsListItemsContainer}>
          {visibleRecipes.length > 0 ? visibleRecipes.map((item) => {
            if (!item) {
              return null;
            }

            return (
              <>
                <Card
                  key={uniqueId(`recipe_${item.nid}_`)}
                  image={item.image}
                  link={`/${item.alias}`}
                  title={`${String(item.title || '').toUpperCase()}`}
                  time={item.total}
                />
              </>
            );
          }) : (
            <div className={styles.cardFilterNoResults}>There are no recipes matching your filter selection. Please adjust your filter options, or, clear them to view more recipes.</div>
          )}
          {/* If the list of visible recipes is less than the available filtered recipes, include load more loader. */
            visibleRecipes.length < filteredRecipes.length && (
              <div className={styles.cardsListLoadMore}>
                <button
                  tabIndex="0"
                  className={styles.cardsListLoadMoreLink}
                  onClick={loadNextPage}
                  onKeyPress={redirectKeyPressHandler(loadNextPage)}
                >
                  {locale.loftLandingPage.buttons.loadMoreButton}
                </button>
              </div>
            )}
        </div>
      </div>
    </Memoized>
  );
};

export default RecipesFilterGrid;